import {
  BlockListItem,
  CmsRouteKey,
  HardwareCategory,
  HardwareColor,
  HardwareCondition,
  HardwareConditionReference,
  HardwareInstalment,
  HardwareListPageCmsData,
  HardwareProductCmsData,
  HardwareSalesStatus,
  HardwareStockStatuses,
  HardwareVariant,
  Image,
  SaleStatus,
  StockStatus,
} from '@/types';

import { roundAmountToMaxTwoDecimals } from '@/global/utils/Format';
import { CmsPageType } from '@/constants';
import { ParsedUrlQuery } from 'querystring';

/* TEMP FIX START Filter out variants + memorySize/Colors that are temporarily unavailable. */
export const hwProductFilterOutTemporarilyUnavailable = (hwProducts: HardwareProductCmsData[]) => {
  return hwProducts.map((hwProduct) => {
    const updatedVariants = hwProduct.variants.filter((variant) => variant.content.temporarilyUnavailable !== true);

    const availableColorNames: string[] = [];
    updatedVariants.forEach((variant) => {
      if (!availableColorNames.includes(variant.content.colorName)) {
        availableColorNames.push(variant.content.colorName);
      }
    });
    const updatedColors = hwProduct.colors.filter((color) => availableColorNames.includes(color.content.colorName));
    const updatedHwProduct = {
      ...hwProduct,
      variants: updatedVariants,
      colors: updatedColors,
    };

    return updatedHwProduct;
  });
};

export const hwProductPageFilterOutTemporarilyUnavailable = (variants: BlockListItem<HardwareVariant>[]) => {
  const updatedVariants = variants.filter((variant) => variant.content.temporarilyUnavailable !== true);

  const updatedMemorySizes: string[] = [];
  updatedVariants.forEach((variant) => {
    if (!updatedMemorySizes.includes(variant.content.memorySize)) {
      updatedMemorySizes.push(variant.content.memorySize);
    }
  });

  return { updatedVariants, updatedMemorySizes };
};
/* TEMP FIX END Filter out variants + memorySize/Colors that are temporarily unavailable. */

/* TEMP FIX START
    Filter out variants that are temporarily unavailable.
    Based on the updated list of variants -> For hw list: filter out colors that are not used in any available variant */
export const filterHardwarePageCmsData = (hardwarePageCmsData: HardwareListPageCmsData | HardwareProductCmsData) => {
  if (hardwarePageCmsData.$type === CmsPageType.HardwareListingPage) {
    const filteredProducts = hwProductFilterOutTemporarilyUnavailable(
      hardwarePageCmsData.hardwareProductList.hardwareProducts
    );

    return {
      ...hardwarePageCmsData,
      hardwareProductList: {
        ...hardwarePageCmsData.hardwareProductList,
        hardwareProducts: filteredProducts,
      },
    };
  }

  const { updatedVariants, updatedMemorySizes } = hwProductPageFilterOutTemporarilyUnavailable(
    hardwarePageCmsData.variants
  );

  return {
    ...hardwarePageCmsData,
    variants: updatedVariants,
    memorySizes: {
      ...hardwarePageCmsData.memorySizes,
      $values: updatedMemorySizes,
    },
  };
};
/* TEMP FIX END - REMOVE AFTER CHANGE ERP */

export const getCmsKeys = (isB2b: boolean, queryModelName: string) => {
  return isB2b
    ? [
        { key: CmsRouteKey.HardwareLabelsB2B },
        { key: CmsRouteKey.HardwareProduct, suffix: `-${queryModelName}`, queryParams: { isB2b } },
      ]
    : [{ key: CmsRouteKey.HardwareLabelsB2C }, { key: CmsRouteKey.HardwareProduct, suffix: `-${queryModelName}` }];
};

export const getHardwareParams = (searchParams: ParsedUrlQuery) => {
  const { farg, storlek, foretag } = searchParams;
  return {
    color: farg?.toString() || '',
    memorySize: storlek?.toString() || '',
    isB2b: foretag === 'true',
  };
};

export const isHardwareVariantValid = (
  selectedColor: string,
  selectedMemorySize?: string,
  hardwareProductCmsData?: HardwareProductCmsData
) => {
  if (!selectedColor) return false;

  if (hardwareProductCmsData?.salesStatus === HardwareSalesStatus.ComingSoon) return false;

  const category = hardwareProductCmsData?.category;
  if (category === HardwareCategory.Router) {
    return (
      hardwareProductCmsData?.variants?.some((variant) => {
        const { colorName } = variant.content;
        return colorName === selectedColor;
      }) || false
    );
  }

  return (
    hardwareProductCmsData?.variants?.some((variant) => {
      const { colorName, memorySize } = variant.content;
      return colorName === selectedColor && memorySize === selectedMemorySize;
    }) || false
  );
};

export const getHardwareItemStatus = (
  saleStatus: HardwareSalesStatus,
  hardwareStockStatuses?: HardwareStockStatuses,
  productReference?: string
) => {
  if (saleStatus === HardwareSalesStatus.ComingSoon) {
    return SaleStatus.ComingSoon;
  }

  if (saleStatus === HardwareSalesStatus.Preorder) {
    return SaleStatus.Preorder;
  }

  if (!productReference || !hardwareStockStatuses) {
    return StockStatus.OutOfStock;
  }

  const stockLevel = hardwareStockStatuses[productReference];
  return stockLevel;
};

export const getHardwareColorFromVariant = (variant: HardwareVariant, colors: HardwareProductCmsData['colors']) => {
  const matchingHardwareColor = colors.find((color) => color.content.colorName === variant.colorName);
  if (matchingHardwareColor) {
    return matchingHardwareColor.content;
  }

  return null;
};

export const getHardwareReference = (
  variants: BlockListItem<HardwareVariant>[],
  selectedColor: string,
  selectedMemorySize: string
) =>
  variants.find((variant) => {
    const { colorName, memorySize } = variant.content;
    if (colorName === selectedColor && memorySize === selectedMemorySize) {
      return variant.content.productReference;
    }
    return undefined;
  })?.content.productReference;

export const getFirstAvailableProductColor = (
  selectedMemorySize: string,
  variants: HardwareProductCmsData['variants'],
  colors: HardwareProductCmsData['colors'],
  querySelectedColor: string | null,
  salesStatus: HardwareSalesStatus,
  hardwareStockStatuses?: HardwareStockStatuses
): HardwareColor | null => {
  const matchingVariants = variants.filter((variant) => variant.content.memorySize === selectedMemorySize);

  // First lets try and find a variant that matches the preselected color in the url query params
  const matchingVariantWithPreSelectedColor = matchingVariants.find(
    (variant) => variant.content.colorName === querySelectedColor && variant
  );

  if (matchingVariantWithPreSelectedColor) {
    const matchingColor = getHardwareColorFromVariant(matchingVariantWithPreSelectedColor.content, colors);
    if (matchingColor) return matchingColor;
  }

  // Otherwise if no color was pre-selected or no variant matched the preselected color,
  // then pick the first variant and the matching color
  // then we verify the stockLevel
  // lastly, to be sure we verify that we take a variant that has a color that exists
  // in the colors array. Editors can make mistakes in the cms configuration
  const firstVariantWithExistingColor = matchingVariants.find((variant) => {
    const variantColorName = variant.content.colorName;
    const matchingColorExists = colors.some((color) => color.content.colorName === variantColorName);

    const itemStatus = getHardwareItemStatus(salesStatus, hardwareStockStatuses, variant.content.productReference);

    return matchingColorExists && (itemStatus === StockStatus.FewInStock || itemStatus === StockStatus.InStock)
      ? variant
      : false;
  });

  // If we don't find any variant with matching color/or matching color but not in stock, then return the first variant's color object
  if (!firstVariantWithExistingColor) return getHardwareColorFromVariant(matchingVariants[0].content, colors);

  // We've made sure we have a variant that has a color that matches an existing color
  // Lets find that color in the array of colors and return that object
  return getHardwareColorFromVariant(firstVariantWithExistingColor.content, colors);
};

// Umbraco's "empty" date
export const OnGoingDate = '0001-01-01T00:00:00';

export const getIsDiscountActive = (discountEndDate: string, discountedTotalPrice: string) => {
  if (!discountedTotalPrice) return false;

  return new Date(discountEndDate) >= new Date() || discountEndDate === OnGoingDate;
};

export const getMonthlyCost = (fullPrice: string, instalmentMonths: string) => {
  const costPerInstalment = (parseInt(fullPrice, 10) / parseInt(instalmentMonths, 10)).toString();
  return roundAmountToMaxTwoDecimals(costPerInstalment).toString();
};

export const getHardwareInstalmentPrices = (
  instalment: Pick<
    HardwareInstalment,
    | 'discountEndDate'
    | 'discountedTotalPrice'
    | 'totalPrice'
    | 'numberOfInstalments'
    | 'discountedTotalPriceExclVat'
    | 'totalPriceExclVat'
  >
) => {
  const {
    discountEndDate,
    discountedTotalPrice,
    totalPrice,
    numberOfInstalments,
    discountedTotalPriceExclVat,
    totalPriceExclVat,
  } = instalment;
  const isDiscountActive = getIsDiscountActive(discountEndDate, discountedTotalPrice);
  const price = isDiscountActive ? discountedTotalPrice : totalPrice;
  const monthlyCost = getMonthlyCost(price, numberOfInstalments);

  const priceExclVat = isDiscountActive ? discountedTotalPriceExclVat : totalPriceExclVat;
  const monthlyCostExclVat = getMonthlyCost(priceExclVat, numberOfInstalments);

  const originalMonthlyCost = getMonthlyCost(totalPrice, numberOfInstalments);
  const originalMonthlyCostExclVat = getMonthlyCost(totalPriceExclVat, numberOfInstalments);

  const hasDiscountedPrice = discountedTotalPrice !== '' || discountedTotalPriceExclVat !== '';

  return {
    monthlyCost,
    monthlyCostExclVat,
    originalMonthlyCost,
    originalMonthlyCostExclVat,
    isDiscountActive,
    hasDiscountedPrice,
    totalPrice: price,
    totalPriceExclVat: priceExclVat,
  };
};

export const getFirstVariantInStock = (
  salesStatus: HardwareSalesStatus,
  variants: HardwareProductCmsData['variants'],
  hardwareStockStatuses?: HardwareStockStatuses
) => {
  if (salesStatus === HardwareSalesStatus.ComingSoon) return variants[0].content;

  const firstVariantInStock = variants.find((variant) => {
    const variantProductReference = variant.content.productReference;
    const itemStatus = getHardwareItemStatus(salesStatus, hardwareStockStatuses, variantProductReference);
    const variantInStock = itemStatus === StockStatus.FewInStock || itemStatus === StockStatus.InStock;

    return variantInStock;
  });

  if (firstVariantInStock) return firstVariantInStock.content;

  // if no variants are in stock, return the first variant
  return variants[0].content;
};

export const getMatchingVariantBySizeAndColor = (
  variants: HardwareProductCmsData['variants'],
  selectedMemorySize: string,
  selectedColorName: string | ''
) => {
  const variantsWithMatchingMemorySize = variants.filter(
    (variant) => variant.content.memorySize === selectedMemorySize
  );
  const matchingVariant = variantsWithMatchingMemorySize.find(
    (variant) => variant.content.colorName === selectedColorName
  );

  return matchingVariant;
};

export const getVariantByPropsInStock = (
  variants: HardwareProductCmsData['variants'],
  selectedMemorySize: string,
  selectedColorName: string | undefined,
  hardwareStockStatuses: HardwareStockStatuses | undefined,
  salesStatus: HardwareSalesStatus
) => {
  const variantsWithMatchingMemorySize = variants.filter(
    (variant) => variant.content.memorySize === selectedMemorySize
  );
  const matchingVariant = variantsWithMatchingMemorySize.find(
    (variant) => variant.content.colorName === selectedColorName
  );

  if (!matchingVariant) return null;

  const itemStatus = getHardwareItemStatus(
    salesStatus,
    hardwareStockStatuses,
    matchingVariant.content.productReference
  );
  if (itemStatus === StockStatus.FewInStock || itemStatus === StockStatus.InStock) {
    return matchingVariant.content;
  }

  return null;
};

export const getHardwareProductSeoImage = (
  selectedColor: string | null,
  data: Pick<
    HardwareProductCmsData,
    'brand' | 'pageSharingImage' | 'colors' | 'salesStatus' | 'variants' | 'metaData' | 'modelName'
  >
) => {
  // cms page sharing image always takes precedence
  if (data.pageSharingImage) return data.pageSharingImage;

  // Otherwise take the image of the matching selected color (if a color is selected in the query params)
  const seoImage: Image = {
    id: 0,
    name: '',
    url: '',
  };

  if (selectedColor) {
    const selectedHardwareColor = data.colors.find((color) => color.content.colorName === selectedColor);
    if (selectedHardwareColor) {
      seoImage.url = selectedHardwareColor.content.images[0].url;
    }
  } else {
    // finally if no color is selected, take the first available variant in stock and take the image of the matching color
    const firstAvailableVariant = getFirstVariantInStock(
      data.salesStatus,
      data.variants,
      data.metaData?.hardwareStockStatuses
    );
    if (firstAvailableVariant) {
      const matchingHardwareColor = data.colors.find(
        (color) => color.content.colorName === firstAvailableVariant.colorName
      );
      seoImage.url = matchingHardwareColor?.content.images[0].url ?? '';
    }
  }

  seoImage.name = `${data.brand} ${data.modelName}`;

  return seoImage;
};

export const getHardwareCondition = (
  conditions?: HardwareCondition[],
  condition?: HardwareConditionReference
): HardwareCondition | undefined => {
  if (!conditions || !condition) return undefined;
  return conditions.find((c) => c.name === condition);
};

export const getNumberOfVariantSizes = (variants: HardwareProductCmsData['variants']) => {
  if (variants.length === 0) return 0;

  const memorySizes = [variants[0].content.memorySize];
  variants.forEach((variant) => {
    if (!memorySizes.includes(variant.content.memorySize)) {
      memorySizes.push(variant.content.memorySize);
    }
  });

  return memorySizes.length;
};

export const getSameColorVariantOrFirstInStock = (
  variants: HardwareProductCmsData['variants'],
  memorySize: string,
  selectedColorName: string,
  hardwareStockStatuses: HardwareStockStatuses | undefined,
  salesStatus: HardwareSalesStatus
) => {
  let matchingVariant = getVariantByPropsInStock(
    variants,
    memorySize,
    selectedColorName,
    hardwareStockStatuses,
    salesStatus
  );

  if (!matchingVariant) {
    // then default to first in stock
    const memorySizeVariants = variants.filter((variant) => variant.content.memorySize === memorySize);
    matchingVariant = getFirstVariantInStock(salesStatus, memorySizeVariants, hardwareStockStatuses);
  }

  return matchingVariant;
};

// False if memory size is "0", "0 GB", "0GB" or somesuch
export const hasMemorySize = (memorySize: string): boolean => {
  return !!memorySize && memorySize.toUpperCase().replace('GB', '').trim() !== '0';
};
